// ** React Imports
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Dashboard = lazy(() => import('../../views/apps/dashboard'));

const Chat = lazy(() => import('../../views/apps/chat'));
const Todo = lazy(() => import('../../views/apps/todo'));
const File = lazy(() => import('../../views/apps/file'));
const Email = lazy(() => import('../../views/apps/email'));
const Kanban = lazy(() => import('../../views/apps/kanban'));
const Calendar = lazy(() => import('../../views/apps/calendar'));

const InvoiceAdd = lazy(() => import('../../views/apps/invoice/add'));
const InvoiceList = lazy(() => import('../../views/apps/invoice/list'));
const InvoiceEdit = lazy(() => import('../../views/apps/invoice/edit'));
const InvoicePrint = lazy(() => import('../../views/apps/invoice/print'));
const InvoicePreview = lazy(() => import('../../views/apps/invoice/preview'));

const EcommerceShop = lazy(() => import('../../views/apps/ecommerce/shop'));
const EcommerceDetail = lazy(() => import('../../views/apps/ecommerce/detail'));
const EcommerceWishlist = lazy(() => import('../../views/apps/ecommerce/wishlist'));
const EcommerceCheckout = lazy(() => import('../../views/apps/ecommerce/checkout'));

const Setting = lazy(() => import('../../views/apps/setting'));

const UserList = lazy(() => import('../../views/apps/user/list'));
const UserView = lazy(() => import('../../views/apps/user/view'));
const Roles = lazy(() => import('../../views/apps/user/roles'));
const Permissions = lazy(() => import('../../views/apps/user/permissions'));

const PostList = lazy(() => import('../../views/apps/post/list'));
const PostView = lazy(() => import('../../views/apps/post/PostView'));
const PostForm = lazy(() => import('../../views/apps/post/PostForm'));

const PostCatesList = lazy(() => import('../../views/apps/post/cates/list'));
const PostCateView = lazy(() => import('../../views/apps/post/cates/PostCateView'));
const PostCateForm = lazy(() => import('../../views/apps/post/cates/PostCateForm'));

const PostTagsList = lazy(() => import('../../views/apps/post/tags/list'));
const PostTagView = lazy(() => import('../../views/apps/post/tags/PostTagView'));
const PostTagForm = lazy(() => import('../../views/apps/post/tags/PostTagForm'));

const TemplateList = lazy(() => import('../../views/apps/template/list'));
const TemplateView = lazy(() => import('../../views/apps/template/preview'));
const TemplateForm = lazy(() => import('../../views/apps/template/TemplateForm'));

const TemplateCatesList = lazy(() => import('../../views/apps/template/cates/list'));
const TemplateCateView = lazy(() => import('../../views/apps/template/cates/TemplateCateView'));
const TemplateCateForm = lazy(() => import('../../views/apps/template/cates/TemplateCateForm'));

const SliderList = lazy(() => import('../../views/apps/slider/list'));
const SliderView = lazy(() => import('../../views/apps/slider/preview'));
const SliderForm = lazy(() => import('../../views/apps/slider/SliderForm'));

const SliderCatesList = lazy(() => import('../../views/apps/slider/cates/list'));
const SliderCateView = lazy(() => import('../../views/apps/slider/cates/SliderCateView'));
const SliderCateForm = lazy(() => import('../../views/apps/slider/cates/SliderCateForm'));

const ProjectList = lazy(() => import('../../views/apps/project/list'));
const ProjectView = lazy(() => import('../../views/apps/project/preview'));
const ProjectForm = lazy(() => import('../../views/apps/project/ProjectForm'));

const ProjectCatesList = lazy(() => import('../../views/apps/project/cates/list'));
const ProjectCateView = lazy(() => import('../../views/apps/project/cates/ProjectCateView'));
const ProjectCateForm = lazy(() => import('../../views/apps/project/cates/ProjectCateForm'));

const WebsiteList = lazy(() => import('../../views/apps/website/list'));
const WebsiteView = lazy(() => import('../../views/apps/website/WebsiteView'));
const WebsiteForm = lazy(() => import('../../views/apps/website/WebsiteForm'));

const WebsiteCatesList = lazy(() => import('../../views/apps/website/cates/list'));
const WebsiteCateView = lazy(() => import('../../views/apps/website/cates/WebsiteCateView'));
const WebsiteCateForm = lazy(() => import('../../views/apps/website/cates/WebsiteCateForm'));
const WebsiteTagsList = lazy(() => import('../../views/apps/website/tags/list'));
const WebsiteTagView = lazy(() => import('../../views/apps/website/tags/WebsiteTagView'));
const WebsiteTagForm = lazy(() => import('../../views/apps/website/tags/WebsiteTagForm'));

const ProductList = lazy(() => import('../../views/apps/product/list'));
const ProductDetail = lazy(() => import('../../views/apps/product/detail'));
const ProductForm = lazy(() => import('../../views/apps/product/ProductForm'));

const ProductCatesList = lazy(() => import('../../views/apps/product/cates/list'));
const ProductCateView = lazy(() => import('../../views/apps/product/cates/view'));
const ProductCateForm = lazy(() => import('../../views/apps/product/cates/ProductCateForm'));

const ProductTagsList = lazy(() => import('../../views/apps/product/tags/list'));
const ProductTagView = lazy(() => import('../../views/apps/product/tags/ProductTagView'));
const ProductTagForm = lazy(() => import('../../views/apps/product/tags/ProductTagForm'));

const DocumentList = lazy(() => import('../../views/apps/document/list'));
const DocumentView = lazy(() => import('../../views/apps/document/DocumentView'));
const DocumentForm = lazy(() => import('../../views/apps/document/DocumentForm'));

const DocumentCatesList = lazy(() => import('../../views/apps/document/cates/list'));
const DocumentCateView = lazy(() => import('../../views/apps/document/cates/DocumentCateView'));
const DocumentCateForm = lazy(() => import('../../views/apps/document/cates/DocumentCateForm'));

const DocumentTagsList = lazy(() => import('../../views/apps/document/tags/list'));
const DocumentTagView = lazy(() => import('../../views/apps/document/tags/DocumentTagView'));
const DocumentTagForm = lazy(() => import('../../views/apps/document/tags/DocumentTagForm'));

const CloudSever = lazy(() => import('../../views/apps/cloud-sever/list/Table'));
const CloudSeverView = lazy(() => import('../../views/apps/cloud-sever/view/Table'));

const AppRoutes = [
  {
    element: <Dashboard />,
    path: '/apps/dashboard',
  },
  {
    element: <Setting />,
    path: '/apps/setting',
  },
  {
    element: <Email />,
    path: '/apps/email',
    meta: {
      appLayout: true,
      className: 'email-application',
    },
  },
  {
    element: <Email />,
    path: '/apps/email/:folder',
    meta: {
      appLayout: true,
      className: 'email-application',
    },
  },
  {
    element: <Email />,
    path: '/apps/email/label/:label',
    meta: {
      appLayout: true,
      className: 'email-application',
    },
  },
  {
    element: <Email />,
    path: '/apps/email/:filter',
  },
  {
    path: '/apps/chat',
    element: <Chat />,
    meta: {
      appLayout: true,
      className: 'chat-application',
    },
  },
  {
    element: <File />,
    path: '/apps/file',
    meta: {
      appLayout: true,
      className: 'file-application',
    },
  },
  {
    element: <Todo />,
    path: '/apps/todo',
    meta: {
      appLayout: true,
      className: 'todo-application',
    },
  },
  {
    element: <Todo />,
    path: '/apps/todo/:filter',
    meta: {
      appLayout: true,
      className: 'todo-application',
    },
  },
  {
    element: <Todo />,
    path: '/apps/todo/tag/:tag',
    meta: {
      appLayout: true,
      className: 'todo-application',
    },
  },
  {
    element: <Calendar />,
    path: '/apps/calendar',
  },
  {
    element: <Kanban />,
    path: '/apps/kanban',
    meta: {
      appLayout: true,
      className: 'kanban-application',
    },
  },
  {
    element: <InvoiceList />,
    path: '/apps/invoice/list',
  },
  {
    element: <InvoicePreview />,
    path: '/apps/invoice/preview/:id',
  },
  {
    path: '/apps/invoice/preview',
    element: <Navigate to="/apps/invoice/preview/4987" />,
  },
  {
    element: <InvoiceEdit />,
    path: '/apps/invoice/edit/:id',
  },
  {
    path: '/apps/invoice/edit',
    element: <Navigate to="/apps/invoice/edit/4987" />,
  },
  {
    element: <InvoiceAdd />,
    path: '/apps/invoice/add',
  },
  {
    path: '/apps/invoice/print',
    element: <InvoicePrint />,
    meta: {
      layout: 'blank',
    },
  },
  {
    element: <EcommerceShop />,
    path: '/apps/ecommerce/shop',
    meta: {
      className: 'ecommerce-application',
    },
  },
  {
    element: <EcommerceWishlist />,
    path: '/apps/ecommerce/wishlist',
    meta: {
      className: 'ecommerce-application',
    },
  },
  {
    path: '/apps/ecommerce/product-detail',
    element: <Navigate to="/apps/ecommerce/product-detail/apple-i-phone-11-64-gb-black-26" />,
    meta: {
      className: 'ecommerce-application',
    },
  },
  {
    path: '/apps/ecommerce/product-detail/:product',
    element: <EcommerceDetail />,
    meta: {
      className: 'ecommerce-application',
    },
  },
  {
    path: '/apps/ecommerce/checkout',
    element: <EcommerceCheckout />,
    meta: {
      className: 'ecommerce-application',
    },
  },
  {
    element: <UserList />,
    path: '/apps/user/list',
  },
  {
    path: '/apps/user/view',
    element: <Navigate to="/apps/user/view/1" />,
  },
  {
    element: <UserView />,
    path: '/apps/user/view/:id',
  },
  {
    element: <Roles />,
    path: '/apps/roles',
  },
  {
    element: <Permissions />,
    path: '/apps/permissions',
  },
  // Post module
  {
    element: <PostList />,
    path: '/apps/post/list',
  },
  {
    element: <PostView />,
    path: '/apps/post/view/:id',
  },
  {
    element: <PostForm />,
    path: '/apps/post/add',
  },
  {
    element: <PostForm />,
    path: '/apps/post/edit/:id',
  },
  {
    element: <PostCatesList />,
    path: '/apps/post-cate/list',
  },
  {
    element: <PostCateView />,
    path: '/apps/post-cate/view/:id',
  },
  {
    element: <PostCateForm />,
    path: '/apps/post-cate/add',
  },
  {
    element: <PostCateForm />,
    path: '/apps/post-cate/edit/:id',
  },
  {
    element: <PostTagsList />,
    path: '/apps/post-tag/list',
  },
  {
    element: <PostTagView />,
    path: '/apps/post-tag/view/:id',
  },
  {
    element: <PostTagForm />,
    path: '/apps/post-tag/add',
  },
  {
    element: <PostTagForm />,
    path: '/apps/post-tag/edit/:id',
  },
  // Project module
  {
    element: <ProjectList />,
    path: '/apps/project/list',
  },
  {
    element: <ProjectView />,
    path: '/apps/project/view/:id',
  },
  {
    element: <ProjectForm />,
    path: '/apps/project/add',
  },
  {
    element: <ProjectForm />,
    path: '/apps/project/edit/:id',
  },
  {
    element: <ProjectCatesList />,
    path: '/apps/project-cate/list',
  },
  {
    element: <ProjectCateView />,
    path: '/apps/project-cate/view/:id',
  },
  {
    element: <ProjectCateForm />,
    path: '/apps/project-cate/add',
  },
  {
    element: <ProjectCateForm />,
    path: '/apps/project-cate/edit/:id',
  },
  // Template module
  {
    element: <TemplateList />,
    path: '/apps/template/list',
  },
  {
    element: <TemplateView />,
    path: '/apps/template/view/:id',
  },
  {
    element: <TemplateForm />,
    path: '/apps/template/add',
  },
  {
    element: <TemplateForm />,
    path: '/apps/template/edit/:id',
  },
  {
    element: <TemplateCatesList />,
    path: '/apps/template-cate/list',
  },
  {
    element: <TemplateCateView />,
    path: '/apps/template-cate/view/:id',
  },
  {
    element: <TemplateCateForm />,
    path: '/apps/template-cate/add',
  },
  {
    element: <TemplateCateForm />,
    path: '/apps/template-cate/edit/:id',
  },
  // Slider module
  {
    element: <SliderList />,
    path: '/apps/slider/list',
  },
  {
    element: <SliderView />,
    path: '/apps/slider/view/:id',
  },
  {
    element: <SliderForm />,
    path: '/apps/slider/add',
  },
  {
    element: <SliderForm />,
    path: '/apps/slider/edit/:id',
  },
  {
    element: <SliderCatesList />,
    path: '/apps/slider-cate/list',
  },
  {
    element: <SliderCateView />,
    path: '/apps/slider-cate/view/:id',
  },
  {
    element: <SliderCateForm />,
    path: '/apps/slider-cate/add',
  },
  {
    element: <SliderCateForm />,
    path: '/apps/slider-cate/edit/:id',
  },
  // Website module
  {
    element: <WebsiteList />,
    path: '/apps/website/list',
  },
  {
    element: <WebsiteView />,
    path: '/apps/website/view/:id',
  },
  {
    element: <WebsiteForm />,
    path: '/apps/website/add',
  },
  {
    element: <WebsiteForm />,
    path: '/apps/website/edit/:id',
  },
  {
    element: <WebsiteCatesList />,
    path: '/apps/website-cate/list',
  },
  {
    element: <WebsiteCateView />,
    path: '/apps/website-cate/view/:id',
  },
  {
    element: <WebsiteCateForm />,
    path: '/apps/website-cate/add',
  },
  {
    element: <WebsiteCateForm />,
    path: '/apps/website-cate/edit/:id',
  },
  {
    element: <WebsiteTagsList />,
    path: '/apps/website-tag/list',
  },
  {
    element: <WebsiteTagView />,
    path: '/apps/website-tag/view/:id',
  },
  {
    element: <WebsiteTagForm />,
    path: '/apps/website-tag/add',
  },
  {
    element: <WebsiteTagForm />,
    path: '/apps/website-tag/edit/:id',
  },
  // Product module
  {
    element: <ProductList />,
    path: '/apps/product/list',
  },
  {
    element: <ProductDetail />,
    path: '/apps/product/view/:id',
    meta: {
      className: 'ecommerce-application',
    },
  },
  {
    element: <ProductForm />,
    path: '/apps/product/add',
  },
  {
    element: <ProductForm />,
    path: '/apps/product/edit/:id',
  },
  {
    element: <ProductCatesList />,
    path: '/apps/product-cate/list',
  },
  {
    element: <ProductCateView />,
    path: '/apps/product-cate/view/:id',
    meta: {
      className: 'ecommerce-application',
    },
  },
  {
    element: <ProductCateForm />,
    path: '/apps/product-cate/add',
  },
  {
    element: <ProductCateForm />,
    path: '/apps/product-cate/edit/:id',
  },
  {
    element: <ProductTagsList />,
    path: '/apps/product-tag/list',
  },
  {
    element: <ProductTagView />,
    path: '/apps/product-tag/view/:id',
  },
  {
    element: <ProductTagForm />,
    path: '/apps/product-tag/add',
  },
  {
    element: <ProductTagForm />,
    path: '/apps/product-tag/edit/:id',
  },
  // Document module
  {
    element: <DocumentList />,
    path: '/apps/document/list',
  },
  {
    element: <DocumentView />,
    path: '/apps/document/view/:id',
  },
  {
    element: <DocumentForm />,
    path: '/apps/document/add',
  },
  {
    element: <DocumentForm />,
    path: '/apps/document/edit/:id',
  },
  {
    element: <DocumentCatesList />,
    path: '/apps/document-cate/list',
  },
  {
    element: <DocumentCateView />,
    path: '/apps/document-cate/view/:id',
  },
  {
    element: <DocumentCateForm />,
    path: '/apps/document-cate/add',
  },
  {
    element: <DocumentCateForm />,
    path: '/apps/document-cate/edit/:id',
  },
  {
    element: <DocumentTagsList />,
    path: '/apps/document-tag/list',
  },
  {
    element: <DocumentTagView />,
    path: '/apps/document-tag/view/:id',
  },
  {
    element: <DocumentTagForm />,
    path: '/apps/document-tag/add',
  },
  {
    element: <DocumentTagForm />,
    path: '/apps/document-tag/edit/:id',
  },
  {
    element: <CloudSever />,
    path: '/apps/cloud-sever',
  },
  {
    element: <CloudSeverView />,
    path: '/apps/cloud-sever/view/:id',
  },
];

export default AppRoutes;
